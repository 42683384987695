<template>
  <div class="content">
    <el-form :model="edit" :rules="rules" ref="ruleForm" class="tb-form" label-width="100px" label-position="right">
      <el-form-item label="添加公告：" prop="title">
        <el-input v-model="edit.title" placeholder="请填写公告标题"></el-input>
      </el-form-item>
      <el-form-item label="公告内容：" prop="content">
        <el-input v-model="edit.content" :rows="20" type="textarea" placeholder="请填写公告内容"></el-input>
      </el-form-item>
      <el-form-item label="状态：">
        <el-radio-group v-model="edit.status">
          <el-radio :label="1">生效中</el-radio>
          <el-radio :label="0">未生效</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <div class="submit">
      <el-button type="primary" @click="onConfirm">确定</el-button>
      <el-button @click="onCancel">取消</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NoticeAdd',
  data() {
    return {
      id: null,
      edit: {
        title: '',
        content: '',
        status: 1
      },
      rules: {
        title: [{required: true, message: '请填写标题'}],
        content: [{required: true, message: '请填写内容'}]
      },
    }
  },
  mounted() {
    this.id = this.$route.query.id * 1 || null
    if (this.id) {
      this.getDetail()
    }
  },
  methods: {
    async getDetail() {
      const url = `admin/notice/detail?id=${this.id}`
      const data = await this.$https.get(url)
      if (!data) return
      this.edit.title = data.name
      this.edit.content = data.content
      this.edit.status = data.status
    },
    onConfirm () {
      const params = {
        name: this.edit.title,
        content: this.edit.content,
        status: this.edit.status
      }
      if (this.id) params.id = this.id
      const url = 'admin/notice/edit'
      this.$https.post(url, params).then(data => {
        if (data) {
          this.$message.success(this.id ? '编辑成功' : '添加成功')
          this.$router.push('/notice/list')
        }
      })
    },
    onCancel() {
      this.$router.push('/notice/list')
    }
  }
}
</script>

<style scoped lang="scss">
.tb-form {
  width: 586px;
  margin: 0 auto;
}
.submit {
  text-align: center;
}
</style>
